import React, { useState, useEffect } from "react";
import { Row, Col } from "reactstrap";
import { connect } from "react-redux";
import Layout from "../../components/Layout";
import BuildingGuideModal from "../../components/Modals/BuildingGuideModal/BuildingGuideModal";
import SideNav from "../../components/SideNav/SideNav";
import { getVideos } from "../../reducers/Videos/actions";
import { urlDescription } from '../../utils/pageDescription'
import ReactPlayer from "react-player";
import {
  FacebookShareButton,
  FacebookIcon,
  LinkedinShareButton,
  LinkedinIcon
} from "react-share";
import "./styles.scss";

const Videos = ({ videos, page, getVideos }) => {
  const [isModalOpen, setModalOpen] = useState(false);

  useEffect(() => {
    getVideos({ page });
  }, [getVideos, page]);

  let url = "";

  if (typeof window !== "undefined") {
    url = new URL(window.location.href);
  }

  return (
    <Layout description={urlDescription[url.pathname]}>
      <div className="building-library-title">
        <h1 className="h2 font-weight-bold">Полезна информация</h1>
      </div>
      <div className="news-wrapper">
        <div className="news-page-card-container">
          <div className="news-page-title">
            <h2 className="h3 font-weight-bold">Видео</h2>
          </div>

          <Row className="news-page-card-row">
            <Col className='mb-5 sidenav-menu-col' xs={12} md={4} lg={4}>
              <SideNav videos active="videos" />
            </Col>
            <Col className="videos-column" lg={8} md={8}>
              {videos &&
                videos.map((v) => {
                  return (
                    <div key={v.id} className="video-embedded pb-5">
                      <div className="video-responsive">
                        <ReactPlayer
                          url={v.url}
                          controls={true}
                        />
                      </div>
                      <div className="video-description pt-4 pb-3">
                        <h4 className="font-weight-bold">{v.title}</h4>
                        <p>{v.description}</p>
                      </div>
                      <div>
                        <FacebookShareButton
                          url={"https://www.facebook.com/Building-companion-Bulgaria-106030925324315"}
                        >
                          <FacebookIcon size={32} round />
                        </FacebookShareButton>
                        <LinkedinShareButton
                          className="margin-left5"
                          url={"https://www.linkedin.com/company/xella-bulgaria/?viewAsMember=true"}
                        >
                          <LinkedinIcon size={32} round />
                        </LinkedinShareButton>
                      </div>
                      <hr />
                    </div>
                  );
                })}
            </Col>
          </Row>
        </div>
      </div>
      <BuildingGuideModal
        title="Попълнете формата и свалете брошурата"
        isOpen={isModalOpen}
        onClick={() => setModalOpen(!isModalOpen)}
      />
    </Layout>
  );
};

const mapStateToProps = (state) => {
  return {
    videos: state.videos.data,
    page: state.videos.current_page,
  };
};

const mapDispatchToProps = {
  getVideos,
};

export default connect(mapStateToProps, mapDispatchToProps)(Videos);
