import React, { useState, useEffect } from "react";
import {
  Button,
  Input,
  InputGroup,
  Container,
  Modal,
  ModalBody,
} from "reactstrap";
import { Row, Col } from "reactstrap";
import CustomizedCheckbox from "../../Checkbox/Checkbox";
import {
  postData,
  setUserName,
  setUserPhone,
  setUserMail,
  setUserLocation,
  setUserData,
} from "../../../reducers/BuildingGuide/actions";
import { postProduct } from "../../../reducers/Products/actions";
import {
  validateEmail,
  validatePhone,
  nameRegex,
  phoneRegex,
  locationRegex,
} from "../../../utils/util";
import { connect } from "react-redux";
import CloseIcon from "@mui/icons-material/Close";
import "./styles.scss";

const BuildingGuideModal = ({
  isOpen,
  title,
  onClick,
  onCodeClick,
  id,
  postData,
  productRequest,
  postProduct,
  buttonText,
  guide,
  user,
  setUserData,
}) => {
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [mail, setMail] = useState("");
  const [location, setLocation] = useState("");
  const [description, setDescription] = useState("");
  const [gdpr, setGDPR] = useState(false);
  const [terms, setTerms] = useState(false);
  const [marketing, setMarketing] = useState(false);
  const [validate, setValidate] = useState(false);

  const replaceHistory = () => {
    if (typeof window !== "undefined" && isOpen === true) {
      window.history.replaceState(null, "", "/building-guide/thank-you");
    }
  };

  useEffect(() => {
    setName("");
    setPhone("");
    setMail("");
    setLocation("");
    setDescription("");
    setTerms(false);
    setGDPR(false);
    setMarketing(false);
    setValidate(false);
  }, []);

  const validateFields = (productRequest) => {
    if (
      (productRequest && description.length === 0) ||
      name.length === 0 ||
      !nameRegex.test(name) ||
      !validatePhone(phone) ||
      !phoneRegex.test(phone) ||
      phone.length === 0 ||
      !validateEmail(mail) ||
      mail.length < 5 ||
      location.length === 0 ||
      !locationRegex.test(location) ||
      !gdpr ||
      !terms ||
      !marketing
    ) {
      return true;
    } else return false;
  };

  const validateGuideFields = () => {
    if (
      user.name.length === 0 ||
      !nameRegex.test(user.ame) ||
      !validatePhone(user.phone) ||
      !phoneRegex.test(user.phone) ||
      user.phone.length === 0 ||
      !validateEmail(user.mail) ||
      user.mail.length < 5 ||
      user.location.length === 0 ||
      !locationRegex.test(user.location) ||
      !gdpr ||
      !terms ||
      !marketing
    ) {
      return true;
    } else return false;
  };

  const validateInputs = (name, phone, mail, location) => {
    if (!nameRegex.test(name) || name.length > 35 || name === "") {
      return "Невалидно име! Допустими символи: латиница и кирилица.";
    }
    if (!phoneRegex.test(phone) || phone.length > 25 || phone === "") {
      return "Невалиден телефонен номер! Допустими символи: само цифри.";
    }
    if (!validateEmail(mail) || mail.length > 254 || mail === "") {
      return "Невалиден имейл адрес! Допустими символи: латиница, цифри, и специални символи.";
    }
    if (location.length > 35 || location === "" || location.length === 0) {
      return "Невалидна локация! Допустими символи до 35";
    }
    if (!gdpr) {
      return "Липсва съгласие за обработка на лични данни.";
    }
    if (!terms) {
      return "Липсва съгласие за правилата за ползване.";
    }
    if (!marketing) {
      return "Липсва съгласие за правилата за маркетинг.";
    }
    return "";
  };

  const validateGuideInputs = (user) => {
    if (
      !nameRegex.test(user.name) ||
      user.name.length > 35 ||
      user.name === ""
    ) {
      return "Невалидно име! Допустими символи: латиница и кирилица.";
    }
    if (
      !phoneRegex.test(user.phone) ||
      user.phone.length > 25 ||
      user.phone === ""
    ) {
      return "Невалиден телефонен номер! Допустими символи: само цифри.";
    }
    if (
      !validateEmail(user.mail) ||
      user.mail.length > 254 ||
      user.mail === ""
    ) {
      return "Невалиден имейл адрес! Допустими символи: латиница, цифри, и специални символи.";
    }
    if (
      user.location.length > 35 ||
      user.location === "" ||
      user.location.length === 0
    ) {
      return "Невалидна локация! Допустими символи до 35";
    }
    if (!gdpr) {
      return "Липсва съгласие за обработка на лични данни.";
    }
    if (!terms) {
      return "Липсва съгласие за правилата за ползване.";
    }
    if (!marketing) {
      return "Липсва съгласие за правилата за маркетинг.";
    }
    return "";
  };

  return (
    <div className="modal-wrapper">
      <div className="modal-container">
        <Modal className="building-guide-modal" size="lg" isOpen={isOpen}>
          <div className="close-btn d-flex justify-content-end">
            <i
              role="button"
              tabIndex={0}
              className="close-btn"
              onKeyDown={onClick}
              onClick={() => {
                setName("");
                setPhone("");
                setMail("");
                setLocation("");
                setDescription("");
                setTerms(false);
                setGDPR(false);
                setMarketing(false);
                setValidate(false);
                onClick();
              }}
            >
              <CloseIcon />
            </i>
          </div>

          <ModalBody>
            <Row>
              <Col sm={12}>
                <h4 className="font-weight-bold no-decoration text-center mb-4">
                  {title}
                </h4>
              </Col>
            </Row>

            <Row className="my-3 mx-auto">
              <Col sm={12} lg={6}>
                <InputGroup className="input-group-custom mb-2 ">
                  <Input
                    value={guide ? user.name : name}
                    maxLength={35}
                    placeholder="Име и фамилия"
                    className="input-field py-3 px-3 border-radius box-shadow"
                    onChange={(e) => {
                      setUserData({ name: e.target.value });
                      // setUserName(e.target.value)
                      setName(e.target.value);
                    }}
                  />
                </InputGroup>
              </Col>

              <Col sm={12} lg={6}>
                <InputGroup className="input-group-custom mb-2 ">
                  <Input
                    value={guide ? user.phone : phone}
                    maxLength={25}
                    type="number"
                    placeholder="Телефон"
                    className="input-field py-3 px-3 border-radius box-shadow"
                    onChange={(e) => {
                      // setUserPhone(e.target.value)
                      setUserData({ phone: e.target.value });
                      setPhone(e.target.value);
                    }}
                  />
                </InputGroup>
              </Col>

              <Col sm={12} lg={6}>
                <InputGroup className="input-group-custom mb-2 ">
                  <Input
                    value={guide ? user.mail : mail}
                    type="email"
                    maxLength={254}
                    placeholder="Имейл"
                    className="input-field py-3 px-3 border-radius box-shadow"
                    onChange={(e) => {
                      // setUserMail(e.target.value)
                      setUserData({ mail: e.target.value });
                      setMail(e.target.value);
                    }}
                  />
                </InputGroup>
              </Col>

              <Col sm={12} lg={6}>
                <InputGroup className="input-group-custom mb-2 ">
                  <Input
                    value={guide ? user.location : location}
                    type="text"
                    maxLength={25}
                    placeholder="Локация"
                    className="input-field py-3 px-3 border-radius box-shadow"
                    onChange={(e) => {
                      // setUserLocation(e.target.value)
                      setUserData({ location: e.target.value });
                      setLocation(e.target.value);
                    }}
                  />
                </InputGroup>
              </Col>
              <Col sm={12}>
                {productRequest && (
                  <div>
                    <Input
                      className="project-input-textarea py-3 border-radius box-shadow"
                      type="textarea"
                      placeholder="Съобщение"
                      onChange={(e) => setDescription(e.target.value)}
                    />
                  </div>
                )}
              </Col>
            </Row>
            <div>
              <Row className="privacy-checkbox-row w-100">
                <div className="py-2 d-flex align-items-center">
                  <CustomizedCheckbox
                    checked={gdpr}
                    onChange={() => setGDPR(!gdpr)}
                  />
                  <label className="checkbox-label" htmlFor="privacy">
                    <p className="my-0">
                      Съгласен съм личните ми данни да бъдат обработвани от
                      "КСЕЛА България" ЕООД в съответствие с{" "}
                      <a
                        href="#"
                        className="blue-link"
                        onClick={() =>
                          window.open(
                            `https://building-companion.bg/GDPR`,
                            "_blank"
                          )
                        }
                      >
                        Политиката за защита на личните данни на КСЕЛА България
                      </a>
                      .
                    </p>
                  </label>
                </div>
                <div className="py-2 d-flex align-items-center">
                  <CustomizedCheckbox
                    checked={terms}
                    onChange={() => setTerms(!terms)}
                  />
                  <label className="checkbox-label" htmlFor="privacy">
                    <p className="my-0">
                      Съгласен съм и приемам{" "}
                      <a
                        href="#"
                        className="blue-link"
                        onClick={() =>
                          window.open(
                            `https://building-companion.bg/terms-and-conditions?terms-and-conditions=0`,
                            "_blank"
                          )
                        }
                      >
                        правилата за ползване на уебсайта
                      </a>
                      .
                    </p>
                  </label>
                </div>
                <div className="py-2 d-flex align-items-center">
                  <CustomizedCheckbox
                    checked={marketing}
                    onChange={() => setMarketing(!marketing)}
                  />
                  <label className="checkbox-label" htmlFor="privacy">
                    Съгласен съм личните ми данни да бъдат използвани за
                    маркетингови цели.
                  </label>
                </div>
              </Row>
            </div>
            <div>
              {validateFields(productRequest) && validate && (
                <div className="data-alert mt-3 mx-auto px-2">
                  {guide
                    ? validateGuideInputs(user)
                    : validateInputs(name, phone, mail, location)}
                </div>
              )}
            </div>
            <div className="no-decoration text-center">
              <Container className="mt-3 btn-container d-flex justify-content-center">
                <Button
                  className="box-shadow custom-btn w-50 py-3 text-uppercase mb-3"
                  onClick={() => {
                    if (guide) {
                      if (validateGuideFields()) {
                        setValidate(true);
                        return;
                      }
                      if (onClick) {
                        replaceHistory();
                        onClick();
                      }
                      if (onCodeClick) {
                        onCodeClick();
                      }
                      postData({
                        catalog_id: Number(id),
                        full_name: user.name,
                        phone_number: user.phone,
                        email: user.mail,
                        location: user.location,
                        gdpr_consent: gdpr,
                        terms_consent: terms,
                        marketing_consent: marketing,
                      });
                      setValidate(false);
                    }
                    if (validateFields(productRequest)) {
                      setValidate(true);
                      return;
                    }
                    if (onClick) {
                      onClick();
                    }
                    if (onCodeClick) {
                      onCodeClick();
                    }
                    if (productRequest) {
                      postProduct({
                        product_id: Number(id),
                        full_name: name,
                        phone_number: phone,
                        email: mail,
                        location: location,
                        description: description,
                        gdpr_consent: gdpr,
                        terms_consent: terms,
                        marketing_consent: marketing,
                      });
                      setName("");
                      setPhone("");
                      setMail("");
                      setLocation("");
                      setDescription("");
                      setTerms(false);
                      setGDPR(false);
                      setMarketing(false);
                      setValidate(false);
                      return;
                    }
                    setName("");
                    setPhone("");
                    setMail("");
                    setLocation("");
                    setDescription("");
                    setTerms(false);
                    setGDPR(false);
                    setMarketing(false);
                    setValidate(false);
                  }}
                >
                  {buttonText ? buttonText : "Свалете брошурата"}
                </Button>
              </Container>
            </div>
          </ModalBody>
        </Modal>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.guides.user,
  };
};

const mapDispatchToProps = {
  postData,
  postProduct,
  setUserData,
};

export default connect(mapStateToProps, mapDispatchToProps)(BuildingGuideModal);
